import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MQTTClient from "../../setupConfig.js";
import SideBar from "./SideBar.js";
import Canvas from "./Canvas.js";
import axios from "axios";
import JoyStick from "./joystick.js";
import ThemeProvider from "../Context/Context.js";
import { usePathDraw,useAIPathDraw, useRecordPath } from "../Context/Context.js";

const Home = () => {
  const [tags, setTags] = useState({});
  const navigate = useNavigate();
  const [cameraZoom, setCameraZoom] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [joystickPosition, setJoystickPosition] = useState({ x: 0, y: 0 });
  const {ToRecord}=usePathDraw();
  const {pathRecord,setPathRecord,showStation,setShowStation,showpath, setShowpath,canvasWidth,setCanvasWidth,cameraOffset,setCameraOffset,selectedPath,setSelectedPath,stationRecord,setStationRecord,stationMarkedCoordinate,setStationMarkedCoordinate,AIPathSensitivity, setAIPathSensitivity}=useRecordPath();
  const { AIpath } = useAIPathDraw();

  const handleRangeChange = (event) => {
    console.log(cameraZoom);
    
    setCameraZoom(event.target.value/100);
  };
  const handleAIPathChange = (event) => {
    console.log(AIPathSensitivity);
    
    setAIPathSensitivity(Math.floor(event.target.value));
  };
  const handleJoystickMove = (stickStatus) => {
    // console.log("Joystick moved:", stickStatus);
    setJoystickPosition({
      x: stickStatus.x,
      y: stickStatus.y,
    });
  };
  
  useEffect(() => {
    const fetchCompanies =  async() => {
      try {
        const response = await axios.get("https://drobot-admin-v2-a2def93839bb.herokuapp.com/home", {
          withCredentials: true, // Ensure cookies are included in the request
        });
        
        if (response) {
          console.log(response.data);
         
          setCompanies(response.data);
        } else {
          console.error('Failed to fetch company data');
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchCompanies();
  },[]);
   
  useEffect(() => {
    const joystick =  async() => {
      console.log("joystick working");
    {
      const joy3Param = { "title": "joystick3" };
      const Joy3 = new JoyStick('joy3Div', joy3Param, handleJoystickMove);
    }  
  };
  if(ToRecord)
  joystick();
  }, [ToRecord]);

    useEffect(() => {
    const mqttClient = new MQTTClient(setTags);

    return () => {
      mqttClient.client.disconnect();
    };
  },[]);
  
  // useEffect(() => {
  //   if (!window.localStorage.getItem("auth")) {
  //     navigate("/login");
  //   }
  // });

  return (
      <div className="App font-Poppins overflow-hidden">
        <SideBar cameraZoom={cameraZoom} />
        <Canvas tags={tags}  cameraZoom={cameraZoom} setCameraZoom={setCameraZoom} joystickPosition={joystickPosition} />
        <div className={`flex justify-center items-center z-50 -rotate-90 bg-[#015D81] rounded-full absolute right-1 ${ToRecord?" bottom-52":"bottom-20"}   py-[5px] px-2 gap-2
 `}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M13.417 7.00049L0.583659 7.00049C0.583659 8.70229 1.2597 10.3344 2.46306 11.5378C3.66641 12.7411 5.29852 13.4172 7.00033 13.4172C8.70213 13.4172 10.3342 12.7411 11.5376 11.5378C12.741 10.3344 13.417 8.70229 13.417 7.00049Z"
              fill="white"
            />
            <path
              d="M13.417 7.00049L0.583659 7.00049"
              stroke="white"
              stroke-width="1.16667"
              stroke-miterlimit="10"
            />
            <path
              d="M0.583659 7.00041C0.583659 10.5442 3.4565 13.4171 7.00033 13.4171C10.5442 13.4171 13.417 10.5442 13.417 7.00041C13.417 3.45658 10.5442 0.58374 7.00033 0.58374C3.4565 0.58374 0.583659 3.45658 0.583659 7.00041Z"
              stroke="white"
              stroke-width="1.16667"
              stroke-miterlimit="10"
              stroke-linecap="square"
            />
          </svg>
           <input
      type="range"
      id="aiwindowcontrol"
      className="w-[100px] border-none h-[4px] bg-[#015D8199] accent-white"
      min="20"
      max="100"
      onChange={(e) => handleRangeChange(e)}
    />
        </div>
        {AIpath && (
  <div
    className="flex justify-center items-center z-50 -rotate-90 bg-[#015D81] rounded-full absolute right-2 bottom-96 py-[8px] px-4 gap-2"
  >
    <input
      type="range"
      id="aiwindowcontrol"
      className="w-[100px] border-none h-[4px] bg-[#015D8199] accent-white"
      min="1"
      max="5"
      step="1"
      value={AIPathSensitivity } // This will initialize the slider with the correct AIPathSensitivity value
      onChange={(e) => handleAIPathChange(e)}
    />
  </div>
)}

       {ToRecord&& (<div id="joy3Div" callback={handleJoystickMove} className="right-2 bottom-0" style={{ width: 120, height: 120, position: 'fixed', opacity: 1 }}>
</div>
)};
      </div>
  );
};

export default Home;
