import React, { useMemo } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const TaskByStation = ({ filteredTasks }) => {
  const stationCounts = {};

  // Flatten task_station arrays and count occurrences for each station
  filteredTasks
    .flatMap((task) => task.task_detail?.task_station || [])
    .forEach((station) => {
      const formattedStation =
        station.charAt(0).toUpperCase() + station.slice(1); // Capitalize station name
      if (stationCounts[formattedStation]) {
        stationCounts[formattedStation] += 1;
      } else {
        stationCounts[formattedStation] = 1;
      }
    });

  // Convert stationCounts to an array, sort by count in descending order
  const data = useMemo(() => {
    const topStationColors = [
      "#A0E3D3",
      "#C7B5E1",
      "#4A7C59",
      "#B3C7E6",
      "#E6C7B3",
      "#D3A0A0",
      "#C7E6B3",
      "#E6D3B3",
      "#D3C7E6",
      "#B3E6D3",
      "#E6B3D3",
      "#B3D3E6",
      "#E6B3B3",
      "#B3E6E6",
      "#E6E6B3",
      "#D3E6B3",
      "#E6B3A0",
      "#A0D3E6",
      "#E6A0B3",
      "#B3A0E6",
      "#D3B3A0",
      "#A0E6B3",
      "#A0E6E6",
      "#B3A0D3",
      "#E6A0A0",
      "#D3A0E6",
      "#E6D3A0",
      "#A0C7E6",
      "#E6A0D3",
    ];
    

    // Sort stations by task count in descending order and assign colors
    return Object.keys(stationCounts)
      .map((station, index) => ({
        name: `Station ${station}`,
        value: stationCounts[station],
        color: index < 30 ? topStationColors[index] : "#CCC" // Assign color based on index
      }))
      .sort((a, b) => b.value - a.value); // Sort in descending order
  }, [filteredTasks]);


  const getStationColor = (index) => {
    const topStationColors = [
      "#A0E3D3", "#C7B5E1", "#4A7C59", "#B3C7E6", "#E6C7B3",
      "#D3A0A0", "#C7E6B3", "#E6D3B3", "#D3C7E6", "#B3E6D3",
      "#E6B3D3", "#B3D3E6", "#E6B3B3", "#B3E6E6", "#E6E6B3",
      "#D3E6B3", "#E6B3A0", "#A0D3E6", "#E6A0B3", "#B3A0E6",
      "#D3B3A0", "#A0E6B3", "#A0E6E6", "#B3A0D3", "#E6A0A0",
      "#D3A0E6", "#E6D3A0", "#A0C7E6", "#E6A0D3",
    ];
    return index < topStationColors.length ? topStationColors[index] : "#CCC";
  };
  const sortedStations = Object.keys(stationCounts)
    .map((station, index) => ({
      name: `Station ${station}`,
      value: stationCounts[station],
      color: getStationColor(index), // Assign color based on index
    }))
    .sort((a, b) => b.value - a.value); // Sort in descending order

  // Function to assign color based on top 5 stations
  

  return (
    <div style={containerStyle}>
      <h2 style={cardTitleStyle}>Count of Tasks by Station:</h2>
      <div style={chartAndListContainerStyle}>
        {/* Chart fills the upper half using ResponsiveContainer */}
        <div style={fullWidthChartStyle}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={sortedStations}
                startAngle={180}
                endAngle={0}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius="100%"
                innerRadius="75%"
                paddingAngle={0}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        {/* Scrollable stations list */}
        <div style={scrollableListStyle}>
          {sortedStations.map((item, index) => (
            <div key={index} style={stationItemStyle}>

              <div style={headerStyle}>
                <span
                  style={{ ...colorBoxStyle, backgroundColor: item.color }}
                ></span>
              </div>
              <div style={headerStyle}>
              <span style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#374151",
                  }}>
                {item.name}:
              </span>
              <p style={{ marginLeft: "8px" , fontSize: "14px" }}>{item.value} tasks</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Inline styles
const containerStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const cardTitleStyle = {
  fontSize: "1.2rem",
  fontWeight: "bold",
  marginBottom: "16px",
};

const chartAndListContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  height: "600px", // Adjusted to fill half for chart, half for list
};

const fullWidthChartStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "125%", // Chart occupies the top half of the container
};

const scrollableListStyle = {
  width: "100%",
  height: "50%", // Fixed height for the list to occupy the lower half
  overflowY: "auto", // Enables scrolling
    marginTop: "-5rem",
};
const headerStyle = {
  display: "flex",
  // flexDirection: 'column',
  alignItems: "center",
  gap: "8px",
  marginBottom: "4px",
  marginTop: "4px",
};

const stationItemStyle = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#F3F4F6",
  borderRadius: "10px",
  marginBottom: "8px",
};

const colorBoxStyle = {
  display: "inline-block",
  width: "4px",
  height: "36px",
  marginRight: "8px",
  marginLeft: "8px",
  borderRadius: "2px",
};

export default TaskByStation;
