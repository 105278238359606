import React, { useRef, useEffect, useState, startTransition } from "react";
import { usePathDraw,useAIPathDraw, useRecordPath } from "../Context/Context.js";
const Canvas = ({ tags,cameraZoom,setCameraZoom,joystickPosition}) => {
  // const [cameraZoom, setCameraZoom] = useState(1);
  // Declare these variables
  const { ToRecord } = usePathDraw();
  const { AIpath,setAIPath,AIpathArray, setAIpathArray } = useAIPathDraw();
  const {pathRecord,setPathRecord,showStation,setShowStation,showBots,setShowBots,showpath, setShowpath,canvasWidth,setCanvasWidth,cameraOffset,setCameraOffset,selectedPath,setSelectedPath,selectedStation,setSelectedStation,stationRecord,setStationRecord,stationMarkedCoordinate,setStationMarkedCoordinate,AIPathSensitivity, setAIPathSensitivity,tracePath,setTracePath,tagName,settagName,botTraceArray,setBotTraceArray,jitterbotCoordinates,setjitterbotCoordinates}=useRecordPath();
  const [ startRecord,setStartRecord ] = useState(false);
  const selectedTag = tags[tagName];
  const canvasRef = useRef(null);
  const updateOffset = () => {
    setCameraOffset((prevOffset) => ({
      x: prevOffset.x - joystickPosition.x / 10,
      y: prevOffset.y + joystickPosition.y / 10,
    }));
  };
  useEffect(() => {
    if (ToRecord) updateOffset();
    let animationFrameId;

    const animate = () => {
      if (ToRecord) {
        updateOffset();
      }
      animationFrameId = requestAnimationFrame(animate);
    };
     animate();
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [joystickPosition, ToRecord]);

  useEffect(() => {
    const canvas = canvasRef.current;
    setCanvasWidth(canvas.width);
    const ctx = canvas.getContext("2d");
    const companymap= window.sessionStorage.getItem("companyName");
    const MAX_ZOOM = 5;
    const MIN_ZOOM = 0.1;
    const SCROLL_SENSITIVITY = 0.000001;
    const DRAG_SENSITIVITY = 0.6;

    var xFlipped = 1;
    var yFlipped = -1;
    var scaleFactor = companymap=="Drobot 1"?24.1304520095363:85.27279871798186,
      originX = companymap=="Drobot 1"?(23462.84145899498 / scaleFactor):(26619.109239724097/scaleFactor),
      originY = companymap=="Drobot 1"?(yFlipped * (-55755.01018319527 / scaleFactor)):(yFlipped * (-98757.17160965082 / scaleFactor));
    var real_distance_waypoints = 50; //in cm
    var record_distance_factor = real_distance_waypoints / (scaleFactor * 10);
    var windowinnerWidth = window.innerWidth;
    var windowinnerHeight = window.innerHeight;
    let isDragging = false;
    let dragStart = { x: 0, y: 0 };
    let initialPinchDistance = null;
    let lastZoom = cameraZoom;
    const img = new Image();

    canvas.style.backgroundColor = "white";

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    function removeClosePoints(
      xVals,
      yVals,
      thresholdDistance = record_distance_factor / 5
    ) {

      const newPoints = [{ x: xVals[0], y: yVals[0] }];
      const lastPoints= { x: xVals[xVals.length - 1], y: yVals[yVals.length - 1] };

      for (let i = 1; i < xVals.length; i++) {
        const distance = Math.sqrt(
          (xVals[i] - newPoints[newPoints.length - 1].x) ** 2 +
            (yVals[i] - newPoints[newPoints.length - 1].y) ** 2
        );

        if (distance >= thresholdDistance) {
          newPoints.push({ x: xVals[i], y: yVals[i] });
        }
      }
      newPoints.push(lastPoints);

      return newPoints;
    }
    function addIntermediatePoints(points, thresholdDistance) {
      const newPoints = [points[0]]; // Start with the first point
    
      for (let i = 1; i < points.length; i++) {
        const prevPoint = newPoints[newPoints.length - 1];
        const currPoint = points[i];
    
        const distance = Math.sqrt(
          (currPoint.x - prevPoint.x) ** 2 + (currPoint.y - prevPoint.y) ** 2
        );
    
        if (distance > thresholdDistance) {
          // Calculate the number of points to add
          const numPointsToAdd = Math.ceil(distance / thresholdDistance) - 1;
    
          // Calculate x and y increments for each intermediate point
          const xIncrement = (currPoint.x - prevPoint.x) / (numPointsToAdd + 1);
          const yIncrement = (currPoint.y - prevPoint.y) / (numPointsToAdd + 1);
    
          // Add intermediate points
          for (let j = 1; j <= numPointsToAdd; j++) {
            newPoints.push({
              x: prevPoint.x + xIncrement * j,
              y: prevPoint.y + yIncrement * j,
            });
          }
        }
    
        newPoints.push(currPoint); 
      }
    
      return newPoints;
    }
    
    function smoothPath(points, windowSize) {
      const smoothedPoints = [{ x: points[0].x, y: points[0].y }];

      for (let i = 0; i < points.length; i++) {
        let sumX = 0,
          sumY = 0;

        for (let j = Math.max(0, i - windowSize + 1); j <= i; j++) {
          sumX += points[j].x;
          sumY += points[j].y;
        }

        const averageX = sumX / Math.min(i + 1, windowSize);
        const averageY = sumY / Math.min(i + 1, windowSize);

        smoothedPoints.push({ x: averageX, y: averageY });
      }

      return smoothedPoints;
    }

    function alignPathToAxes(x, y, angleThreshold) {
      const alignedX = [x[0]];
      const alignedY = [y[0]];

      for (let i = 1; i < x.length; i++) {
        const angleX = Math.abs(
          (Math.atan2(y[i] - y[i - 1], x[i] - x[i - 1]) * 180) / Math.PI
        );
        const angleY = Math.abs(
          (Math.atan2(y[i] - y[i - 1], x[i] - x[i - 1]) * 180) / Math.PI - 90
        );

        if (
          (angleX <= angleThreshold || angleX >= 180 - angleThreshold) &&
          (angleY <= 90 + angleThreshold || angleY >= 270 - angleThreshold)
        ) {
          alignedX.push(x[i]);
          alignedY.push(alignedY[alignedY.length - 1]);
        } else if (
          (angleY <= angleThreshold || angleY >= 180 - angleThreshold) &&
          (angleX <= 90 + angleThreshold || angleX >= 270 - angleThreshold)
        ) {
          alignedX.push(alignedX[alignedX.length - 1]);
          alignedY.push(y[i]);
        } else {
          alignedX.push(x[i]);
          alignedY.push(y[i]);
        }
      }

      return { pose: { x: alignedX, y: alignedY } };
    }
   
    const draw = async() => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      ctx.translate(windowinnerWidth / 2, windowinnerHeight / 2);
      ctx.scale(cameraZoom, cameraZoom);
      ctx.translate(
        -windowinnerWidth / 2 + cameraOffset.x,
        -windowinnerHeight / 2 + cameraOffset.y
      );
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      img.src = companymap === "Drobot 1" ? "Drobot.jpg" : "AFL Accessories.jpg";
      ctx.globalAlpha = 1;
      ctx.drawImage(
        img,
        -img.naturalWidth / 2,
        -img.naturalHeight / 2,
        img.naturalWidth,
        img.naturalHeight
      );
      if(showBots){
      const tagCoordinates = tags;
      const tagIds = Object.keys(tags);
      let numberOfTags = tagIds.length;
      while (numberOfTags !== 0) {
        numberOfTags--;
        const tagId = tagIds[numberOfTags];
        const tag = tagCoordinates[tagId];

        const x =
          (xFlipped * tag.botCoordinateX) / scaleFactor -
          img.naturalWidth / 2 +
          originX;
        const y =
          (yFlipped * tag.botCoordinateY) / scaleFactor -
          img.naturalHeight / 2 +
          originY;

        ctx.fillStyle = "green";
        drawCirc(x, y);
        drawstrokeCirc(x, y);
        ctx.fillStyle = "black";
        drawText(tagId, x - 20, y + 22, 12, "courier");
      }
    }
      if(stationMarkedCoordinate.x!=null){
      ctx.fillStyle = "black";
      drawCirc(stationMarkedCoordinate.x,stationMarkedCoordinate.y);
      // drawstrokeCirc(stationMarkedCoordinate.x,stationMarkedCoordinate.x);
    }
      if(showStation){
        const stationData=window.sessionStorage.getItem("stationData");
        if(stationData){
        const stations=JSON.parse(stationData);
        let numberOfStations = stations.length;
        while (numberOfStations !== 0) {
          numberOfStations--;
          const stationName = stations[numberOfStations].stationName;
          const stationCoordinate = stations[numberOfStations].pose;
          if (!stationCoordinate || typeof stationCoordinate.x !== 'number' || typeof stationCoordinate.y !== 'number') {
            console.error("Station coordinate is missing or invalid for station:", stationName);
            continue;
          }
        ctx.fillStyle = "black";
          drawCirc(
            (xFlipped *  stationCoordinate.x* 10) / scaleFactor - img.naturalWidth / 2 + originX,
            (yFlipped * stationCoordinate.y * 10) / scaleFactor - img.naturalHeight / 2 + originY
          );
          ctx.fillStyle = "black";
          drawText(
            stationName,
            (xFlipped * stationCoordinate.x * 10) / scaleFactor -
              img.naturalWidth / 2 +
              originX -
              20,
            (yFlipped * stationCoordinate.y * 10) / scaleFactor -
              img.naturalHeight / 2 +
              originY -
              10,
            15,
            "courier"
          );
        }
      }
      }
      selectedPath.forEach((path) => {
        if (path != null) {
          const pathCoordinate = path.pose;
          ctx.strokeStyle = "black";
          const xList = pathCoordinate.x;
          const yList = pathCoordinate.y;
          ctx.beginPath();
          ctx.lineWidth = 3;
    
          for (let i = 1; i < xList.length; i++) {
            ctx.lineTo(
              xFlipped *
                ((xList[i] * 10) / scaleFactor -
                  (xFlipped * img.naturalWidth) / 2 +
                  xFlipped * originX),
              yFlipped *
                ((yList[i] * 10) / scaleFactor -
                  (yFlipped * img.naturalHeight) / 2 +
                  yFlipped * originY)
            );
          }
          ctx.stroke();
          ctx.closePath();
        }
      });

      selectedStation.forEach((station) => {
        if (station != null) {
          const stationCoordinate =station.pose;
          ctx.fillStyle = "black";
          ctx.beginPath();
          ctx.lineWidth = 3;
    
          drawCirc(
            (xFlipped *  stationCoordinate.x* 10) / scaleFactor - img.naturalWidth / 2 + originX,
            (yFlipped * stationCoordinate.y * 10) / scaleFactor - img.naturalHeight / 2 + originY
          );
          ctx.fillStyle = "black";
          drawText(
            station.stationName,
            (xFlipped * stationCoordinate.x * 10) / scaleFactor -
              img.naturalWidth / 2 +
              originX -
              20,
            (yFlipped * stationCoordinate.y * 10) / scaleFactor -
              img.naturalHeight / 2 +
              originY -
              10,
            12,
            "courier"
          );
        }
      });
      if(showpath){
        const pathData=window.sessionStorage.getItem("pathData");
        const paths=JSON.parse(pathData).paths;
        // console.log(JSON.parse(pathData).paths);
        let numberOfpaths = paths.length;
        while (numberOfpaths !== 0) {
  
          numberOfpaths--;
          const pathName = paths[numberOfpaths].pathName;
          
          const pathCoordinate = paths[numberOfpaths].pose;
          // console.log(pathCoordinate);

          ctx.strokeStyle = "black";
          const xList = pathCoordinate.x;
          const yList = pathCoordinate.y;
          ctx.beginPath();
          ctx.lineWidth = 3;
          for (let i = 1; i < xList.length; i++) {
            ctx.lineTo( xFlipped *
                  ((xList[i] * 10) / scaleFactor -
                    (xFlipped * img.naturalWidth) / 2 +
                    xFlipped * originX),
                yFlipped *
                  ((yList[i] * 10) / scaleFactor -
                    (yFlipped * img.naturalHeight) / 2 +
                    yFlipped * originY));
          }
          ctx.stroke();
          ctx.closePath();
      }
      }
      if(ToRecord){
      if (pathRecord.x.length != 0) {
       
        drawplotPath();
       
        if (AIpath) {
          // ai_smooth();
          var xList = pathRecord.x;
          var yList = pathRecord.y;
          let points1 =await  removeClosePoints(xList, yList);
          let points=await addIntermediatePoints(points1,50);
          let lastPointsX=pathRecord.x[pathRecord.x.length-1];
          let lastPointsY=pathRecord.y[pathRecord.y.length-1];


         
          xList = [];
          yList = [];
          var window_size = AIPathSensitivity;
          
          let smoothedPoints = await smoothPath(points, window_size);
          xList.push(pathRecord.x[0]);
          yList.push(pathRecord.y[0]);
          for (let i = 0; i < smoothedPoints.length; i++) {
            xList.push(smoothedPoints[i].x);
            yList.push(smoothedPoints[i].y);
          }
          var straightig_threshold = 10;
          var straighten_points = alignPathToAxes(xList, yList, straightig_threshold);
          xList = straighten_points.pose.x;
          yList = straighten_points.pose.y;
        
          points = await removeClosePoints(xList, yList);
        
          // Prepare the final smoothed path
          xList = [];
          yList = [];
          var window_size = AIPathSensitivity;
          smoothedPoints = await smoothPath(points, window_size);
          for (let i = 0; i < smoothedPoints.length; i++) {
            xList.push(smoothedPoints[i].x);
            yList.push(smoothedPoints[i].y);
          }
        
          var straightig_threshold=10;
          var straighten_points=await alignPathToAxes(xList,yList,straightig_threshold);
          xList=straighten_points.pose.x;
          yList=straighten_points.pose.y;

          points=await removeClosePoints(xList,yList);
         
          xList=[];
          yList=[];
         
          smoothedPoints=await smoothPath(points,window_size)
          for (let i = 0; i < smoothedPoints.length; i++) {
              xList.push(smoothedPoints[i].x);
              yList.push(smoothedPoints[i].y);
          }
          xList.push(lastPointsX);
          yList.push(lastPointsY);
          
        setAIpathArray({
          x:xList,
          y:yList
        });

          ctx.beginPath();
          ctx.moveTo(xList[0], yList[0]);
          ctx.lineWidth = 4;
        
          // Draw the path using the smoothed points
          for (let i = 1; i < xList.length; i++) {
            ctx.lineTo(xList[i], yList[i], xList[i + 1], yList[i + 1]);
          }
          ctx.strokeStyle = "lime";
          ctx.stroke();
          ctx.closePath();
        }
       
      }
    }
    if(tracePath){  
    drawBotPath();  
      drawBotJitterPath();
    }
      requestAnimationFrame(draw);
    };

    function drawCirc(x, y) {
      ctx.beginPath();
      ctx.arc(x, y, 6, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
    }

    function  drawplotPath() {
      
      ctx.strokeStyle = "black";
      const xList = pathRecord.x;
      const yList = pathRecord.y;
      ctx.beginPath();
      ctx.moveTo(xList[0], yList[0]);
      ctx.lineWidth = 3;

      for (let i = 1; i < xList.length; i++) {
        ctx.lineTo(xList[i], yList[i]);
      }
      ctx.stroke();
      ctx.closePath();
    }

    function drawstrokeCirc(x, y) {
      ctx.beginPath();
      ctx.globalAlpha = 0.3;
      ctx.arc(x, y, 14, 0, 2 * Math.PI);
      ctx.fill();
      ctx.globalAlpha = 1;
      ctx.closePath();
    }

    function drawText(text, x, y, size, font) {
      ctx.font = `${size}px ${font}`;
      ctx.fillText(text, x, y);
    }

    function drawBotPath() {
      ctx.strokeStyle = "blue";
      const xList = botTraceArray.x;
          const yList = botTraceArray.y;
          ctx.beginPath();
          // ctx.moveTo(xList[0], yList[0]);
          ctx.lineWidth = 3;
          for (let i = 0; i < xList.length; i++) {
            ctx.lineTo(
              (xFlipped * xList[i]) / scaleFactor -
              img.naturalWidth / 2 +
              originX,
              (yFlipped * yList[i]) / scaleFactor -
              img.naturalHeight / 2 +
              originY
            );
          }
          ctx.stroke();
          ctx.closePath();
    }
    function drawBotJitterPath() {
      ctx.strokeStyle = "red";
      const xList = jitterbotCoordinates.x;
      const yList = jitterbotCoordinates.y;
      ctx.beginPath();
      ctx.lineWidth = 3;
      // ctx.moveTo(xList[0], yList[0]);
      for (let i = 0; i < xList.length; i++) {
        ctx.lineTo(
          (xFlipped * xList[i]) / scaleFactor -
          img.naturalWidth / 2 +
          originX,
          (yFlipped * yList[i]) / scaleFactor -
          img.naturalHeight / 2 +
          originY
        );
        // console.log(jitterbotCoordinates);
      }
      ctx.stroke();
      ctx.closePath();
    }
    const getEventLocation = (e) => {
      if (e.touches && e.touches.length === 1) {
        return { x: e.touches[0].clientX, y: e.touches[0].clientY };
      } else if (e.touches && e.touches.length === 2) {
        return {
          x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
          y: (e.touches[0].clientY + e.touches[1].clientY) / 2,
        };
      } else {
        return { x: e.clientX, y: e.clientY };
      }
    };

    const getMousePosition = (canvasElem, event) => {
      let rect = canvasElem.getBoundingClientRect();
      let record_x = event.clientX - rect.left;
      let record_y = event.clientY - rect.top;
      record_x = (record_x - window.innerWidth / 2) / cameraZoom;
      record_y = (record_y - window.innerHeight / 2) / cameraZoom;
      record_x = record_x + window.innerWidth / 2 - cameraOffset.x;
      record_y = record_y + window.innerHeight / 2 - cameraOffset.y;
      if (stationRecord) {
        setStationMarkedCoordinate({ x: record_x, y: record_y });
      }
    
      if (record_x && record_y) {
        if (startRecord) {
          setPathRecord((prev) => ({
            x: [...prev.x, record_x],
            y: [...prev.y, record_y],
          }));
        }
      }
    };
    
    const onPointerDown = (e) => {
      isDragging = true;
      dragStart.x = getEventLocation(e).x / cameraZoom - cameraOffset.x;
      dragStart.y = getEventLocation(e).y / cameraZoom - cameraOffset.y;
      console.log(stationRecord);
    
      if (ToRecord) {
        setStartRecord(true);
        getMousePosition(canvas,e);
      } else if (stationRecord) {
        getMousePosition(canvas, e);
        setStationRecord(!stationRecord);
      }
    };
    
    const onPointerUp = (e) => {
      setStartRecord(false);
      getMousePosition(canvas,e);
      isDragging = false;
      initialPinchDistance = null;
      lastZoom = cameraZoom;
    };

    const onPointerMove = (e) => {
      
      if (startRecord) {
        getMousePosition(canvas, e);
      }
     
      else if (isDragging && !ToRecord) {
        cameraOffset.x +=
          (getEventLocation(e).x / cameraZoom - dragStart.x - cameraOffset.x) *
          DRAG_SENSITIVITY;
        cameraOffset.y +=
          (getEventLocation(e).y / cameraZoom - dragStart.y - cameraOffset.y) *
          DRAG_SENSITIVITY;
      }
      

      
    };

    const handleTouch = (e, singleTouchHandler) => {
      if (e.touches.length === 1) {
        singleTouchHandler(e);
      } else if (e.type === "touchmove" && e.touches.length === 2) {
        isDragging = false;
        handlePinch(e);
      }
    };

    const handlePinch = (e) => {
      e.preventDefault();

      const touch1 = e.touches[0];
      const touch2 = e.touches[1];

      const currentDistance = Math.hypot(
        touch1.clientX - touch2.clientX,
        touch1.clientY - touch2.clientY
      );

      if (initialPinchDistance === null) {
        initialPinchDistance = currentDistance;
        lastZoom = cameraZoom;
      } else {
        setCameraZoom(
          Math.max(
            MIN_ZOOM,
            Math.min(
              MAX_ZOOM,
              lastZoom * (currentDistance / initialPinchDistance)
            )
          )
        );
      }
    };

    const adjustZoom = (zoomAmount, zoomFactor) => {
      if (!isDragging) {
        if (zoomAmount) {
          setCameraZoom((prevZoom) =>
            Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, prevZoom - zoomAmount))
          );
        } else if (zoomFactor) {
          setCameraZoom((prevZoom) =>
            Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, zoomFactor * lastZoom))
          );
        }
      }
    };
    const findDistance = (oldCoordinateX, oldCoordinateY, newCoordinateX, newCoordinateY) => {
      return Math.sqrt(
        (newCoordinateX - oldCoordinateX) * (newCoordinateX - oldCoordinateX) +
        (newCoordinateY - oldCoordinateY) * (newCoordinateY - oldCoordinateY)
      );
    };
    
    const traceBots = ({ botCoordinateX, botCoordinateY, minDistance }) => {
      setBotTraceArray((prevState) => {
        // If there are no previous coordinates, add the first one.
        if (prevState.x.length === 0 || prevState.y.length === 0) {
          return {
            x: [botCoordinateX],
            y: [botCoordinateY],
          };
        }
    
        const lastX = prevState.x[prevState.x.length - 1];
        const lastY = prevState.y[prevState.y.length - 1];
    
        // Calculate the distance from the last point
        const distance = findDistance(lastX, lastY, botCoordinateX, botCoordinateY);
    
        // Only add the new coordinates if they exceed the minimum distance
        if (distance >= minDistance) {
          return {
            x: [...prevState.x, botCoordinateX],
            y: [...prevState.y, botCoordinateY],
          };
        }
    
        // Return the previous state if the distance is too small
        return prevState;
      });
    
      // Update jitterbotCoordinates as before, but also with the distance threshold
      setjitterbotCoordinates((prevState) => {
        const newJitterX = [...prevState.x, botCoordinateX];
        const newJitterY = [...prevState.y, botCoordinateY];
    
        // Keep the last 100 jitter coordinates
        if (newJitterX.length > 100) {
          newJitterX.shift();
          newJitterY.shift();
        }
    
        return { x: newJitterX, y: newJitterY };
      });
    };
    
    if (tagName) {
      traceBots({
        botCoordinateX: selectedTag.botCoordinateX,
        botCoordinateY: selectedTag.botCoordinateY,
        minDistance: 5, 
      });
    }
    
    canvas.addEventListener("mousedown", onPointerDown);
    canvas.addEventListener("touchstart", (e) => handleTouch(e, onPointerDown));
    canvas.addEventListener("mouseup", onPointerUp);
    canvas.addEventListener("touchend", (e) => handleTouch(e, onPointerUp));
    canvas.addEventListener("mousemove", onPointerMove);
    canvas.addEventListener("touchmove", (e) => handleTouch(e, onPointerMove));
    canvas.addEventListener("wheel", (e) =>
      adjustZoom(e.deltaY * SCROLL_SENSITIVITY)
    );

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();
    draw();

    return () => {
      canvas.removeEventListener("mousedown", onPointerDown);
      canvas.removeEventListener("touchstart", (e) =>
        handleTouch(e, onPointerDown)
      );
      canvas.removeEventListener("mouseup", onPointerUp);
      canvas.removeEventListener("touchend", (e) =>
        handleTouch(e, onPointerUp)
      );
      canvas.removeEventListener("mousemove", onPointerMove);
      canvas.removeEventListener("touchmove", (e) =>
        handleTouch(e, onPointerMove)
      );
      canvas.removeEventListener("wheel", (e) =>
        adjustZoom(e.deltaY * SCROLL_SENSITIVITY)
      );
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [tags, cameraZoom, cameraOffset,stationRecord,botTraceArray,jitterbotCoordinates,stationMarkedCoordinate,startRecord,tracePath,tagName,showBots, ToRecord,showStation,showpath,AIpath,selectedPath,selectedStation,selectedTag,AIPathSensitivity,joystickPosition]);

  return (
    <canvas className="w-[100%] h-[100%]" ref={canvasRef} id="canvas"></canvas>
  );
};

export default Canvas;